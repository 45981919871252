<template>
<div class="d-flex justify-content-center m-auto">
  <img
    :src="Link"
    :alt="$tcust('Brand.desc')"
    :class="props.background ? 'back' : ''"
  >
</div>

</template>

<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "vuex";
const props = defineProps({
  background: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: null,
  },
});
const store = useStore();

const Link = computed(() => {
  let link = "https://eecheck.app/wp-content/uploads/2020/05/eecheck_logo";
  if (props.size === "mini") link += "miniature_";
  store.state.app.theme === "dark"
    ? (link += "fond_couleur")
    : (link += "fond_clair");
  if (props.size && props.size !== "mini") link += "@" + props.size;
  return link + ".png";
});
</script>

<style lang="scss" scoped>
img.back {
  pointer-events: none;
}
div {
  max-width: 700px;
}
img {
  width: 100%;
  max-width: 80vw;
  max-height: 100%;
}
</style>
