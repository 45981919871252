import assign from "@/helpers/deepMerge.js"
import global from "@/lang/global.js"

export const language = async (lang) => {
  const englishFile = await import(/* webpackChunkName: "lang-[request]" */ `@/lang/trans/en-EN.js`);
  const transFile = await import(/* webpackChunkName: "lang-[request]" */ `@/lang/trans/${lang}.js`);

  const contractionsList = [ englishFile.contractionsList, transFile.contractionsList ].flat();

  let res = assign(transFile.messages, { lang }, { contractionsList });
  res = assign(global(res) , res);

  return res
}