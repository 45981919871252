import { createI18n } from 'vue-i18n'
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@/lang/constants'
import global from "@/lang/global.js"
import { contractionsList, messages } from "@/lang/trans/en-EN.js"

const enMessages = messages;
enMessages['contractionsList'] = contractionsList;

export const i18n = createI18n({
  locale: DEFAULT_LANGUAGE, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { "en-EN": {...global(enMessages)} }
})
