import { computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { mutateKey } from '@/helpers';

export default function Setup() {
  const store = useStore();

  const choice = computed({
    get: () => {
      return store.state.licenseChosenKey
    },
    set: (value) => {
      return mutateKey('licenseChosenKey', value)
    }
  });

  const setClasses = () => {
    let buttons = document.querySelectorAll('.LicenseSwitcher');
    for (let button of buttons) {
      if (button) {
        button.classList.remove('chart-bg-sub');
        button.classList.remove('chart-bg-group');
        setTimeout(() => {
          if (store.state.licenseChosenType === 'Sous-license') button.classList.add('chart-bg-sub');
          else if (store.state.licenseChosenType === 1) button.classList.add('chart-bg-group');
        });
      }      
    }
  };

  onMounted(() => {
    setClasses();
  });

  watch(choice, () => {
    setClasses();
  })

  return {
    choice
  };
}

