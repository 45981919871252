function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

function isShallow(item) {
  return Array.isArray(item) && item.find((item) => typeof item === 'object') ? false : true;
}

export default function assign(target, ...sources) {

  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) target[key] = {};
        assign(target[key], source[key]);
      } else {
        if (isShallow(source[key])) Object.assign(target, { [key]: source[key] });
        else {
          if (!target[key]) Object.assign(target, { [key]: [] });
          Object.assign(target, { [key]: source[key].map((item, index) => assign(target[key][index] || {}, item)) });
        }
      }
    }
  }

  return assign(target, ...sources);
}