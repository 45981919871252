<template>
  <div
    :id="'loader-' + random" 
    class="loader w-100 h-100"
  >
    <MDBLoading
      full-screen="false"
      backdrop-color="transparent"
      :parent-selector="'#loader-' + random"
      icon="fas fa-spinner fa-spin fa-2x"
      class="w-fit h-fit m-auto"
    />
  </div>
</template>

<script setup>
import {
  MDBLoading,
} from "mdb-vue-ui-kit";

const random = Math.floor(Math.random() * 1000000);
</script>

<style lang="scss" scoped>
.loader {
  min-height: 100px;
  min-width: 120px;
}
</style>
