<template>
  <div class="w-100 h-100 d-flex justify-content-center align-items-center">
    <Loader v-if="!loaded" />
    <img
      v-else-if="Object.prototype.toString.call(image) === '[object String]' && !imageError"
      :class="imgClass"
      :src="`data:image/png;base64,${image}`"
      :alt="props.alt"
      @error="imageError = true"
      ref="imgToScale"
    >
    <div
      v-else-if="!props.isPP"
      class="bg-box text-muted fst-italic d-flex justify-content-center align-items-center h-100 w-100 p-3"
    >
      <MDBIcon
        icon="camera"
        icon-style="fas"
        size="2x"
        class="m-auto"
      />
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center h-100 w-100"
    >
      <MDBIcon
        icon="user-circle"
        icon-style="fas"
        size="2x"
        class="m-auto"
      />
    </div>
  </div>
</template>

<script setup>
import {
  MDBIcon,
} from "mdb-vue-ui-kit";

import { computed, defineEmits, defineProps, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  image: {
    type: [Number, String],
    default: null,
  },
  alt: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
  scale: {
    type: Boolean,
    default: false,
  },
  isPP: {
    type: Boolean,
    default: false,
  },
});

const imgClass = `${props.size} ${props.scale ? 'scale' : ''} ${props.isPP ? 'rounded-circle' : ''}`;
const imgToScale = ref(null);

const emit = defineEmits(['imgLoaded']);
const loaded = ref(false);
const image = ref(props.image);
const imageError = ref(false);

const scale = () => {
  if (props.scale && imgToScale.value) {
    setTimeout(() => {
      let parent = imgToScale.value.parentNode;
      if (imgToScale.value.offsetWidth < parent.offsetWidth || imgToScale.value.offsetHeight < parent.offsetHeight) {
        let loop = 0;
        let scale = 1;
        while (imgToScale.value.offsetWidth * scale < parent.offsetWidth || imgToScale.value.offsetHeight * scale < parent.offsetHeight) {
          loop++;
          scale += 0.05;
          imgToScale.value.style.cssText = imgToScale.value.style.cssText.replace(/transform: scale(\d+);/g, '');
          imgToScale.value.style.cssText = imgToScale.value.style.cssText + ` transform: scale(${scale});`;
          if (loop > 20) break
        }
      }
    }, 200);
  }
};

onMounted(async () => {
  if (Number.isInteger(props.image)) {
    await store.dispatch("getPhoto", props.image)
    .then((res) => {
      imageError.value = false;
      return image.value = res.OriginalImage;
    })
    .catch(() => {
      return imageError.value = true
    })
    .then(() => {
      loaded.value = true;
      emit('imgLoaded');
      return
    })
    .then(() => {
      scale();
      return
    })
  }
  else return setTimeout(() => {
    loaded.value = true
  });
});

const appWindowSize = computed(() => {
  return store.state.app.windowSize
});
watch(appWindowSize, () => {
  scale();
});
</script>

<style lang="scss" scoped>
img {
  object-fit: cover;
  transition: transform 0.5s;
}
.tiny {
  max-height: 40px;
  // max-width: 50px;
  &-forced {
    min-height: 40px;
    // min-width: 50px;
  }
}
.medium {
  max-height: 200px;
  // max-width: 200px;
  &-forced {
    min-height: 200px;
    // min-width: 200px;
  }
}
.large {
  max-height: 300px;
  // max-width: 400px;
  &-forced {
    min-height: 300px;
    // min-width: 400px;
  }
}
</style>
