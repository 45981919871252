import assign from "@/helpers/deepMerge.js"

export default (trans) => {
  const global = {
    accommodation: {
      appointment: ({ named }) => {
        if (!!named('date') && new Date(named('date')) > global.utils.today) {
          return global.appointment({ named })
        }
        else return trans.accommodation.noAppointment || trans.appointment.noAppointment
      },
    },
    appointment: ({ named }) => {
      if (!named('date')) return trans.appointment.noAppointment
      let appt = new Date(named('date'));
      let dateTime = global.utils.dateTimeFormater(appt);
      let time = `${global.utils.timeFormater(appt)}`;
      if (appt > global.utils.afterTomorrow()) {
        return trans.appointment.default(dateTime)
      }
      else if (appt > global.utils.tomorrow()) {
        return trans.appointment.tomorrow(time)
      }
      else if (appt < global.utils.today()) {
        return trans.appointment.past(dateTime)
      }
      else return trans.appointment.today(time)
    },
    checking: {
      appointment: ({ named }) => {
        if (named('date')) {
          return global.appointment({ named })
        }
        else return trans.checking.noAppointment || trans.appointment.noAppointment
      },
      state: ({ named }) => {
        switch (named('state')) {
          case 0:
            return trans.checking.state.toDo
          case 1:
            return trans.checking.state.inProgress
          case 2:
            return trans.checking.state.done
          case 3:
            return trans.checking.state.canceled
          default:
            return global.error
        }
      },
      status: ({ named }) => {
        switch (named('status')) {
          case 0:
            return trans.checking.status.free
          case 1:
            return trans.checking.status.expert
          default:
            return global.error
        }
      },
    },
    error: ({ named }) => {
      let error = (named("error"));
      try {
        let showError;
        if (error.status === 500) showError = 500;
        else showError = `${error.config.url.replaceAll('/', '_')}_${error.status}`;
        return trans.displayError[showError]
      } catch (error) {
        // console.log(error);
        return trans.displayError.default
      }
    },
    license: {
      validityDate: ({ named }) => {
        let date = global.utils.simpleDateFormater(named("date"));
        return date ? trans.license.validUntil(date) : trans.license.noDate
      },
    },

    functions: {
      contraction: ({ named }) => {
        return global.utils.contractionFormater(named("message"))
      },
      modifier: ({ named }) => {
        return global.utils.modifierFormater(named("message"), named("modifier"))
      },
      util: ({ named }) => {
        return global.utils[named('fct')](named('params'))
      }
    },

    utils: {
      contractionFormater: (message) => {
        for (let expr of trans.contractionsList) {
          // invariable == contraction don't upper/lower like formal => no capital letter
          let cont = expr.invariable ? expr.cont : expr.cont.slice(1);
          for (let formal of expr.formal) {
            let form = expr.invariable ? formal : formal.slice(1);
            message = message.replaceAll(form, cont);
            message = message.replaceAll(form.toLocaleLowerCase(), cont);
          }
        }
        return message
      },

      modifierFormater: (message, modifier) => {
        return global.utils[`modifier_${modifier}`](message)
      },
      modifier_lower: (string) => {
        return string.toLocaleLowerCase()
      },
      modifier_upper: (string) => {
        return string.toLocaleUpperCase()
      },
      modifier_capitalize: (string) => {
        let firstLetter = string.charAt(0) === '<' ? string.indexOf('>') + 1 : 0;
        return string.slice(0, firstLetter) + string.charAt(firstLetter).toLocaleUpperCase() + string.slice(firstLetter + 1)
      },

      today: () => {
        return new Date().setHours(0, 0, 0, 0)
      },
      tomorrow: () => {
        let today = new Date();
        let tomorrow = today.setDate(today.getDate()+1);
        return new Date(tomorrow).setHours(0, 0, 0, 0)
      },
      afterTomorrow: () => {
        let today = new Date();
        let afterTomorrow = today.setDate(today.getDate()+2);
        return new Date(afterTomorrow).setHours(0, 0, 0, 0)
      },

      simpleDateFormater: (dateTime = null) => {
        try {
          if (!dateTime) throw new Error
          return `<span class="cust-date">${new Date(dateTime).toLocaleDateString(trans.lang, {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}</span>`;
        } catch (error) {
          return null
        }
      },
      dateFormater: (dateTime = null) => {
        try {
          if (!dateTime) throw new Error
          return `<span class="cust-date">${new Date(dateTime).toLocaleDateString(trans.lang, {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
          })}</span>`;
        } catch (error) {
          return null
        }
      },
      dateTimeFormater: (dateTime = null) => {
        try {
          if (!dateTime) throw new Error
          return `<span class="cust-date">${new Date(dateTime).toLocaleDateString(trans.lang, {
            weekday: "long",
            month: "long",
            day: "numeric",
            hour: 'numeric',
            minute: 'numeric'
          })}</span>`;
        } catch (error) {
          return null
        }
      },
      timeFormater: (dateTime = null) => {
        try {
          if (!dateTime) throw new Error
          return `<span class="cust-time">${new Date(dateTime).toLocaleTimeString(trans.lang, {
            hour: 'numeric',
            minute: 'numeric'
          })}</span>`;
        } catch (error) {
          return null
        }
      },
    }
  }

  return assign(global, trans);
}