<template>
  <MDBCard
    class="position-relative shadow-3-strong"
    :class="appearance === 'List' ? 'custom-list' : 'custom-card'"
  >
    <div
      class="custom-box"
      :class="props.collapseButton ? 'custom-shadow' : ''"
    >
      <MDBContainer
        class="bg-transparent w-100 h-100 p-0"
        fluid
      > 
        <MDBRow
          class="above d-flex w-100 h-100 m-auto rounded-1 p-0"
          :class="appearance === 'List' ? 'flex-row' : 'flex-column'"
        >
          <div
            v-if="!props.loaded"
            class="inside-block"
          >
            <Loader class="bg-box" />
          </div>
          <slot
            v-else
            :cardCollapse="!cardCollapse"
            :collapseId="collapseId"
          />
        </MDBRow>
      </MDBContainer>      
    </div>
    <button
      v-if="props.collapseButton && props.loaded"
      class="btn btn-transparent shadow-0 text-white w-100 m-0 py-1"
      aria-controls="collapseId"
      :aria-expanded="!cardCollapse"
      @click="cardCollapse = !cardCollapse"
    >
      <div
        v-if="cardCollapse"
        class="d-flex flex-row justify-content-center align-items-center"
      >
        <MDBIcon
          icon="angle-down"
          icon-style="fas"
          size="lg"
        />
        <div class="text mx-2">
          {{ $tcust('GradientContainer.seeMore') }}          
        </div>
        <MDBIcon
          icon="angle-down"
          icon-style="fas"
          size="lg"
        />
      </div>
      <div
        v-else
        class="d-flex flex-row justify-content-center align-items-center"
      >
        <MDBIcon
          icon="angle-up"
          icon-style="fas"
          size="lg"
        />
        <div class="text mx-2">
          {{ $tcust('GradientContainer.seeLess') }}          
        </div>
        <MDBIcon
          icon="angle-up"
          icon-style="fas"
          size="lg"
        />
      </div>
    </button>
  </MDBCard>
</template>

<script setup>
import {
  MDBCard,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-vue-ui-kit";

import { useStore } from "vuex";
const store = useStore();

import { defineProps, ref } from "vue";

const props = defineProps({
  collapseButton: {
    type: Boolean,
    default: false,
  },
  imposeAppearance: {
    type: String,
    default: null,
  },
  loaded: {
    type: Boolean,
    default: true,
  },
});

const appearance = ref(props.imposeAppearance ? props.imposeAppearance : store.state.app.listAppearance);

const cardCollapse = props.collapseButton ? ref(true) : false;
const collapseId = 'collapse' + Math.floor(Math.random() * 100000000);
</script>

<style lang="scss" scoped>
#app.dark-theme .btn:hover {
  background-color: transparent !important;
}
.btn {
  text-transform: none;
}
.btn:hover, .btn:focus {
  & .text {
    text-decoration: underline;
  }
}
#app.dark-theme .btn.btn-transparent:focus {
  background-color: rgba(0, 0, 0, 0) !important;
}
.custom-shadow {
  box-shadow: 0 14px 14px -6px rgba(black, 0.25) !important;
}
</style>

<style lang="scss">
.custom-box {
  padding: 2px;
  border-radius: 10px !important;
  & > * {
    overflow: hidden;
    border-radius: 8px !important;
    & > * {
      margin: -2px !important;
      width: calc(100% + 4px) !important;
    }
  }
}
// .custom-box {
//   // padding: 2px;
//   border-radius: 10px !important;
//   border: solid 2px transparent;
//   & > * {
//     overflow: hidden;
//     border-radius: 10px !important;
//     & > * {
//       margin: -1px !important;
//       width: calc(100% + 4px) !important;
//       height: calc(100% + 4px) !important;
//     }
//   }
// }
.inside-block {
  overflow: hidden;
  &:not(.p-0) {
    padding: 1px !important;
  }
  & > * {
    height: fit-content;
  }
}
.gradient {
  border-radius: 10px !important;
}
.custom-list {
  width: 100%;
  max-width: 750px;
}
.custom-card {
  width: 300px;
  max-width: 95vw;
}
</style>
