<template>
  <div
    :key="person.Id"
    :class="show ? 'py-3' : 'pt-2'"
    class="position-relative bg-input shadow-3"
  >
    <div
      v-if="props.delete_button"
      class="position-absolute top-0 end-0 opacity-80 ProfilInfos-times-align"
    >
      <MDBIcon
        icon="times"
        icon-style="fas"
        class="fa-fw"
        @click="$emit('delete')"
        @keyup.enter="$emit('delete')"
        tabindex="0"
        style="cursor: pointer;"
      />
    </div>
    <div class="d-flex justify-content-center align-items-center flex-wrap m-auto">
      <MDBContainer class="m-0">

        <MDBRow class="w-100 m-0 mb-2">
          <MDBCol
            col="12"
            class="p-0 d-flex flex-row justify-content-center align-items-center w-100"
            :class="person.FullName ? 'fw-bold' : 'text-muted fst-italic'"
            @click="show = !show"
            style="cursor: pointer;"
          >
            <div class="flex-grow-1 text-center" :class="show ? 'mt-n2' : ''">
              {{ person.FullName || $tcust('common.emptyField') }}
            </div>
            <div
              v-show="show"
              class="mt-n2 me-2"
            >
              <Image
                class="me-auto ProfilInfos-image"
                :image="person.Image"
                :isPP="true"
                :size="'tiny'"
                :alt="$tcust('ProfilInfos.alt')"
              />
            </div>
          </MDBCol>
        </MDBRow>

        <MDBCollapse v-model="show">
          <MDBRow class="w-100 m-0 mb-2">
            <MDBCol
              col="12"
              class="p-0 d-flex align-items-center text-muted text-end fst-italic"
            >
              {{ $tcust("Profil.mailAddress") }}
            </MDBCol>
            <MDBCol
              col="12"
              class="ps-4 p-0 d-flex align-items-center align-items-center word-break text-break"
              :class="person.MailAddress ? '' : 'text-muted fst-italic'"
            >
              <div
                v-if="person.MailAddress"
                class="d-flex flex-row flex-wrap"
              >
                <span>{{ person.MailAddress.slice(0, person.MailAddress.indexOf('@')) }}</span>
                <span>{{ person.MailAddress.slice(person.MailAddress.indexOf('@')) }}</span>
              </div>
              <span v-else>
                {{ $tcust('common.emptyField') }}
              </span>
            </MDBCol>
          </MDBRow>

          <MDBRow
            v-if="!(person.CellPhoneNumber || person.PhoneNumber || person.FaxNumber)"
            class="w-100 m-0 mb-2"
          >
            <MDBCol
              col="12"
              class="p-0 d-flex align-items-center text-muted text-end fst-italic"
            >
              {{ $tcust("Profil.phone") }}
            </MDBCol>
            <MDBCol
              col="12"
              class="ps-4 p-0 d-flex align-items-center align-items-center text-muted fst-italic"
            >
              {{ $tcust('common.emptyField') }}
            </MDBCol>
          </MDBRow>

          <div v-else>
            <MDBRow
              v-if="person.CellPhoneNumber"
              class="w-100 m-0"
            >
              <MDBCol
                col="12"
                class="p-0 d-flex align-items-center text-muted text-end fst-italic"
              >
                {{ $tcust("Profil.cellPhoneNumber") }}
              </MDBCol>
              <MDBCol
                col="12"
                class="ps-4 p-0 d-flex align-items-center align-items-center"
                :class="person.CellPhoneNumber ? 'fw-bold' : 'text-muted fst-italic'"
              >
                {{ person.CellPhoneNumber || $tcust('common.emptyField') }}
              </MDBCol>
            </MDBRow>

            <MDBRow
              v-if="person.PhoneNumber"
              class="w-100 m-0"
            >
              <MDBCol
                col="12"
                class="p-0 d-flex align-items-center text-muted text-end fst-italic"
              >
                {{ $tcust("Profil.phoneNumber") }}
              </MDBCol>
              <MDBCol
                col="12"
                class="ps-4 p-0 d-flex align-items-center align-items-center"
                :class="person.PhoneNumber ? 'fw-bold' : 'text-muted fst-italic'"
              >
                {{ person.PhoneNumber || $tcust('common.emptyField') }}
              </MDBCol>
            </MDBRow>

            <MDBRow
              v-if="person.FaxNumber"
              class="w-100 m-0 mb-2"
            >
              <MDBCol
                col="12"
                class="p-0 d-flex align-items-center text-muted text-end fst-italic"
              >
                {{ $tcust("Profil.faxNumber") }}
              </MDBCol>
              <MDBCol
                col="12"
                class="ps-4 p-0 d-flex align-items-center align-items-center"
                :class="person.FaxNumber ? 'fw-bold' : 'text-muted fst-italic'"
              >
                {{ person.FaxNumber || $tcust('common.emptyField') }}
              </MDBCol>
            </MDBRow>
          </div>

          <div v-if="false && person.Address">
            <hr class="w-75 mx-auto">

            <MDBRow class="w-100 m-0 mb-2">
              <MDBCol
                col="12"
                class="p-0 d-flex align-items-center text-muted text-end fst-italic"
              >
                {{ $tcust("Profil.address") }}
              </MDBCol>
              <MDBCol
                col="12"
                class="ps-4 p-0 d-flex align-items-center align-items-center"
                :class="person.Address.FullAddress ? 'fw-bold' : 'text-muted fst-italic'"
              >
                <Address
                  v-if="person.Address.FullAdress"
                  :address="person.Address.FullAddress"
                />
                <span v-else>{{ $tcust('common.emptyField') }}</span>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCollapse>
      </MDBContainer>

      <div class="position-absolute bottom-0 end-0 mb-n1 opacity-80">
        <MDBIcon
          :icon="show ? 'caret-up' : 'caret-down'"
          icon-style="fas"
          class="fa-fw"
          size="lg"
          @click="show = !show"
          @keyup.enter="show = !show"
          tabindex="0"
          style="cursor: pointer;"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  MDBCol,
  MDBCollapse,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-vue-ui-kit";

import { defineProps, onMounted, ref } from "vue";
import { i18n } from '@/plugins/i18n';

const props = defineProps({
  person: { 
    type: Object,
    default: null,
  },
  delete_button: {
    type: Boolean,
    default: false,
  }
});

const show = ref(false);
const person = ref(props.person);

onMounted(() => {
  if (person.value.Type === 0 && !person.value.CivilityLabel) {
    person.value.CivilityLabel = person.value.Civility == 0 ? i18n.global.t('ProfilUpdate.civilityLabel.M') : i18n.global.t('ProfilUpdate.civilityLabel.Mme');
  }
  if (!person.value.FullName) {
    if (person.value.Type === 1) person.value.FullName = person.value.Name;
    else person.value.FullName = `${person.value.CivilityLabel} ${person.value.FirstName} ${person.value.Name || person.value.LastName}`;
  }
})
</script>

<style lang="scss" scoped>
.ProfilInfos-image {
  max-width: 32px;
  max-height: 32px;
  transform: scale(0.8);
}
.ProfilInfos-times-align {
  margin-right: 0.22rem;
}
</style>