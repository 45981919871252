<template>
  <div>
    <CardContainer
      class="gradient h-100 w-100"
    >
      <div class="custom-size bg-box w-100 ps-0 pe-1 d-flex flex-row align-items-center justify-content-start">
        <MDBInput
          class="w-100"
          :placeholder="$tcust('Search.placeholder')"
          v-model="search"
        />
        <MDBIcon
          icon="times"
          icon-style="fas"
          size="lg"
          class="mx-2"
          @click="$helpers.mutateApp('search', '')"
          tabindex="0"
          :title="$tcust('Search.clear')"
          style="cursor: pointer;"
        />
      </div>
    </CardContainer>
  </div>
</template>

<script setup>
import {
  MDBIcon,
  MDBInput
} from "mdb-vue-ui-kit";

import Setup from "./SearchSetup";
const { search } = Setup();
</script>

<style lang="scss" scoped>
@import "styles/var.scss";
input {
  border-right-color: $primary !important;
}
.custom-size {
  min-height: 35px !important;
  max-height: 35px;
}
</style>
