<template>
  <div class="position-relative">
    <div>
      <slot
        v-if="applyAppearance === 'Card'"
        name="Card"
      />
      <slot
        v-if="applyAppearance === 'List'"
        name="List"
      />      
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  appearance: {
    type: String,
    default: null,
  },
  forced: {
    type: Boolean,
    default: false,
  },
  id: {
    type: Number,
    default: 0,
  },
});

const listAppearance = computed(() => {
  return store.state.app.listAppearance
});
const listAppearanceImposed = computed(() => {
  return store.state.app.listAppearanceImposed
});
const applyAppearance = computed(() => {
  if (props.forced) return props.appearance;
  return !listAppearanceImposed.value && props.appearance ? props.appearance : listAppearance.value
});
</script>
