import { createRouter, createWebHashHistory } from 'vue-router'
import { Trans } from '@/plugins/translation'
function load(component) {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${component}.vue`)
}
import store from "../store/index"

const routes = [
  {
    path: '/:lang',
    name: 'Accueil',
    component: load('Accueil'),
    beforeEnter: Trans.routeMiddleware,
    props: true,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: load('Home'),
        meta: {
          requireAuth: true
        },
        children: [
          {
            path: 'accommodations',
            name: 'AccommodationsList',
            component: load('AccommodationsList'),
            children: [
              {
                path: ':accommodationId',
                name: 'Accommodation',
                component: load('AccommodationView'),
                props: true,
              },
              {
                path: 'create',
                name: 'AccommodationPost',
                component: load('AccommodationForm'),
              },
              {
                path: 'update',
                name: 'AccommodationUpdate',
                component: load('AccommodationForm'),
              }
            ]
          },
          {
            path: 'checkings',
            name: 'CheckingsList',
            component: load('CheckingsList'),
            children: [
              {
                path: ':checkingId',
                name: 'Checking',
                component: load('CheckingView'),
              },
              {
                path: 'create',
                name: 'CheckingPost',
                component: load('CheckingForm'),
              },
              {
                path: 'update',
                name: 'CheckingUpdate',
                component: load('CheckingForm'),
              }
            ]
          },
          {
            path: 'licenses',
            name: 'LicensesList',
            component: load('LicensesList'),
            children: [
              {
                path: ':license',
                name: 'License',
                component: load('LicenseView'),
              }
            ]
          },
          {
            path: 'profil',
            name: 'Profil',
            component: load('Profil'),
            children: [
              {
                path: 'update',
                name: 'ProfilUpdate',
                component: load('ProfilUpdate'),
              },
              {
                path: 'password',
                name: 'ChangePassword',
                component: load('ChangePassword'),
              }
            ]
          },
          {
            path: 'paiement',
            name: 'Paiement',
            component: load('Paiement'),
          }
        ]
      },
      {
        path: 'register-confirm/:token',
        name: 'RegisterConfirm',
        component: load('RegisterConfirm'),
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: load('ForgotPassword'),
      },
      {
        path: 'forgot-password-complete',
        name: 'ForgotPasswordComplete',
        component: load('ForgotPasswordComplete'),
      },
      {
        path: 'reset-password/:token',
        name: 'ResetPassword',
        component: load('ResetPassword'),
      },
      {
        path: 'sign-in',
        name: 'SignIn',
        component: load('SignIn'),
      },
      {
        path: 'sign-in-complete',
        name: 'SignInComplete',
        component: load('SignInComplete'),
      }
    ]
  },
  {
    // Redirect user to supported lang version.
    path: '/:pathMatch(.*)*',
    redirect: () => {
      return Trans.getUserSupportedLang();
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  try {
    if (to.meta.requireAuth && !store._state.data.user.UserName) {
      throw new Error
    }
  } catch (error) {
    return { name: 'Accueil', params: { lang: Trans.getUserSupportedLang() } }
  }
})

export default router
