<template>
  <MDBModal
    v-model="show"
    @show="focus"
    @hide="mutateApp('modal', false)"
    @click.prevent="error ? mutateApp('error', false) : ''"
    :class="error ? 'ModalError' : ''"
  >
    <button
      v-if="!error"
      @click="mutateApp('modal', false); mutateApp('error', false)"
      class="ModalClose sticky-top btn btn-primary w-fit ms-auto me-n2 mt-n2 p-2"
    >
      <MDBIcon
        icon="times"
        icon-style="fas"
        class="fa-fw"
        size="2x"
      />
    </button>
    <MDBModalBody :class="error ? 'p-0' : 'mt-n4 p-0'">
      <component v-if="!error" :is="AsyncComp[store.state.app.modal]" />
      <div
        v-else
        class="w-fit mw-100 m-auto p-0"
      >
        <div class="mw-100 bg-danger rounded-5 overflow-hidden p-1 m-0">
          <div class="mw-100 bg-box rounded fst-italic text-center p-2">
            <Error />
          </div>
        </div>
      </div>
    </MDBModalBody>
  </MDBModal>
</template>

<script setup>
import {
  MDBIcon,
  MDBModal,
  MDBModalBody
} from "mdb-vue-ui-kit";

import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { defineAsyncComponent } from 'vue'

import { mutateApp } from '@/helpers'

const route = useRoute();
const store = useStore();

const AsyncComp = {
  "AccommodationForm": defineAsyncComponent(() => import("@/views/AccommodationForm.vue")),
  "ProfilUpdate": defineAsyncComponent(() => import("@/views/ProfilUpdate.vue"))
};

const error = computed(() => {
  return (store.state.app.error && store.state.app.error.status !== 401)
});
const show = computed(() => {
  return !!store.state.app.modal || (error.value && route.name !== "Accueil")
});
const focus = () => {
  document.querySelector('.modal button').focus();
};
</script>

<style lang="scss" scoped>
.modal {
  z-index: 2000;
}
.ModalClose {
  z-index: 2001;
}
@media (max-width: 359.98px) {
  .modal-body {
    padding-left: 0.05rem !important;
    padding-right: 0.05rem !important;
  }
}
</style>

<style lang="scss">
@media (max-width: 359.98px) {
  .modal-body .p-3 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
.ModalError {
  background-color: rgba($color: #000000, $alpha: 0) !important;
  .modal-dialog {
    width: fit-content;
    margin-top: 5rem;
  }
}
</style>
