<template>
  <div>
    <div
      v-if="Object.prototype.toString.call(image) === '[object String]' && !imageError"
      class="cust-image w-100 h-100"
      :style="`background-image: url(data:image/png;base64,${image})`"
      :alt="props.alt"
    />
    <div
      v-else
      class="text-muted fst-italic d-flex justify-content-center align-items-center h-100 w-100 p-3"
    >
      <MDBIcon
        icon="camera"
        icon-style="fas"
        size="2x"
        class="m-auto"
      />
    </div>
  </div>
</template>

<script setup>
import {
  MDBIcon,
} from "mdb-vue-ui-kit";

import { defineProps, onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  image: {
    type: [Number, String],
    default: null,
  },
  alt: {
    type: String,
    default: null,
  },
});

const image = ref(props.image);
const imageError = ref(false);

if (Number.isInteger(props.image)) {
  onMounted(async () => {
    await store.dispatch("getPhoto", props.image)
    .catch(() => {
      imageError.value = true
    })
    .then((res) => {
      imageError.value = false;
      return image.value = res.OriginalImage;
    })
  });
}
</script>

<style lang="scss">
.cust-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>