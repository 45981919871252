<template>
  <CardContainer
    v-if="!['xs', 'sm'].includes($store.state.app.windowSize)"
    class="w-fit bg-secondary"
  >
    <div class="d-flex flex-row p-0">
      <div
        class="inside-block w-100 h-100"
      >
        <div
          role="button"
          class="bg-box d-block ps-2 pe-1 py-1 color-current"
          :title="$tcust('ListAppearanceSwitcher.card')"
          tabindex="0"
          @click="choice = 'Card'"
        >
          <MDBIcon
            icon="th-large"
            icon-style="fas"
            size="lg"
            class="mx-1 mt-1"
          />
        </div>      
      </div>
      <div
        class="inside-block w-100 h-100"
      >
        <div
          role="button"
          class="bg-box d-block ps-1 pe-2 py-1 color-current"
          :title="$tcust('ListAppearanceSwitcher.list')"
          tabindex="0"
          @click="choice = 'List'"
        >
          <MDBIcon
            icon="th-list"
            icon-style="fas"
            size="lg"
            class="mx-1 mt-1"
          />
        </div>      
      </div>
    </div>
  </CardContainer>
</template>

<script setup>
import {
  MDBIcon
} from "mdb-vue-ui-kit";

import Setup from "./ListAppearanceSwitcherSetup";
const { choice } = Setup();
</script>

<style lang="scss" scoped>
.bg-box:hover > *, .bg-box:focus > * {
  color: #1d71b8 !important;
}
</style>
