<template>
  <div class="position-relative">
    <MDBDropdown
      v-model="dropdown"
      btn-group
      class="h-100"
    >
      <MDBDropdownToggle
        class="LicenseSwitcher"
        size="sm"
        :class="['xs'].includes($store.state.app.windowSize) ? 'd-flex align-items-center py-1 px-2' : 'pe-2'"
        :title="['xs'].includes($store.state.app.windowSize) ? $tcust('LicenseSwitcher.button.desc') : ''"
        @click="dropdown = !dropdown"
      >
        <span
          v-if="!['xs'].includes($store.state.app.windowSize)"
        >
          {{ $tcust("LicenseSwitcher.button.text") }}&nbsp;
        </span>
        <MDBIcon
          v-else
          icon="business-time"
          icon-style="fas"
          size="lg"
          class="me-2"
        />
      </MDBDropdownToggle>

      <MDBDropdownMenu :class="$store.state.app.theme === 'light' ? 'border border-primary' : ''">
        <div
          class="fst-italic text-muted ms-3 mt-1"
        >
          {{ 'eecheck one ' + $tcust('LicensesList.personal.badge') }}
        </div>
        <div class="m-2">
          <MDBRadio
            v-model="choice"
            :label="$store.state.user.Person.FullName"
            :name="$store.state.user.OneLicenseKey"
            :value="$store.state.user.OneLicenseKey"
            @click="$helpers.mutateKey('licenseChosen', $store.state.user.OneLicensePlan);
                    $helpers.mutateKey('licenseChosenType', 0)"
          />
        </div>
        <hr
          v-if="$store.state.user.Groups.length"
          class="p-0 my-0 mx-3"
        >

        <div
          v-if="$store.state.user.Groups.length"
          class="fst-italic text-muted ms-3 mt-1"
        >
          eecheck Group
        </div>
        <div
          v-for="(group, index) of $store.state.user.Groups"
          :key="index"
        >
          <div class="m-2">
            <MDBRadio
              v-model="choice"
              :label="group.GroupName"
              :name="group.Group.GroupLicenseKey"
              :value="group.Group.GroupLicenseKey"
              @click="$helpers.mutateKey('licenseChosen', group.Group.GroupLicensePlan);
                      $helpers.mutateKey('licenseChosenType', 1)"
            />
          </div>
          <hr
            v-if="$store.state.user.AuthorizedReadOnlyLicenceKeys.length"
            class="p-0 my-0 mx-3"
          >
        </div>

        <div
          v-if="$store.state.user.Groups.length"
          class="fst-italic text-muted ms-3 mt-1"
        >
          Sous-licenses
        </div>
        <div
          v-for="(sub, index) of $store.state.user.AuthorizedReadOnlyLicenceKeys"
          :key="index"
        >
          <div
            v-if="sub.LicenseType === 'Sous-license'"
            class="m-2"
          >
            <MDBRadio
              v-model="choice"
              :label="sub.FullName"
              :name="sub.LicenseKey"
              :value="sub.LicenseKey"
              @click="$helpers.mutateKey('licenseChosen', sub);
                      $helpers.mutateKey('licenseChosenType', 'Sous-license')"
            />
          </div>
          <hr
            v-if="sub.LicenseType === 'Sous-license' && index !== $store.state.user.AuthorizedReadOnlyLicenceKeys.length - 1"
            class="p-0 my-0 mx-3"
          >
        </div>
      </MDBDropdownMenu>
    </MDBDropdown>
  </div>
</template>

<script setup>
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBIcon,
  MDBRadio,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

const dropdown = ref(false);

import Setup from "./LicenseSwitcherSetup";
const { choice } = Setup();
</script>

<style lang="scss" scoped>
@import "styles/var.scss";
.dropdown-menu {
  min-width: 220px;
  padding: 0.5rem;
}
</style>
