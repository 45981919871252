export const contractionsList = [
  {
    formal: ["Appointment", "Appointments"],
    cont: "Appt"
  },
  {
    formal: ["Reference"],
    cont: "Ref"
  },
  {
    formal: ["Surface"],
    cont: "Surf"
  },
]

export const messages = {
  accommodation: {
    access: "Access",
    accessCodes: "No access codes specified | Access codes : {message}",
    DoorNumber: "No door number specified | Door number : {message}",
    checkingsNumber: "No checking | 1 checking | {n} checkings",
    surface: "Surface : {n} m²",
    type: "1 room | {n} rooms",
    isFurnished: "Furnished",
    isNotFurnished: "Not furnished",
    reference: "Reference : ",
    Summary: {
      goTo: "Go to"
    },
    View: {
      displayButton: {
        show: "View content",
        hide: "Hide"
      }
    }
  },
  appointment: {
    default: (dateTime) => { return `Appointment on ${dateTime}` },
    tomorrow: (time) => { return `Appointment tomorrow at ${time}` },
    today: (time) => { return `Appointment today at ${time}` },
    past: (date) => { return `${date} appointment` },
    noAppointment: "No appointment scheduled",
  },
  checking: {
    check: "Checking",
    in: "Incoming",
    out: "Outgoing",
    checkIn: "@:checking.in @.lower:checking.check",
    checkOut: "@:checking.out @.lower:checking.check",
    noAppointment: "Unplanned appointment",
    occupantsCount: "Vacant | 1 occupant | {n} occupants",
    occupant: "Occupant | Occupants",
    owner: "Owner | Owners",
    expert: "Expert",
    representative: "Representative | Representatives",
    roomsCount: "Not specified | 1 room | {n} rooms",
    comment: "Comment",
    state: {
      toDo: "To do",
      inProgress: "In progress",
      done: "Done and signed",
      canceled: "Checking canceled",
    },
    status: {
      free: "Free license",
      expert: "Expert license",
    },
    annexes: {
      empty: "No annexe",
      title: "Annexes",
      attic: "Attic | 1 attic | {n} attics",
      box: "Box | 1 box | {n} boxes",
      cellar: "Cellar | 1 cellar | {n} cellars",
      garage: "Garage | 1 garage | {n} garages",
      indoorParking: "Indoor car park | 1 indoor car park | {n} indoor car parks",
      outdoorParking: "Outdoor car park | 1 car park | {n} car parks",
      storeRoom: "Storeroom | 1 storeroom | {n} storerooms",
    },
  },
  common: {
    dropdown: {
      toogle: {
        desc: 'Scroll content'
      }
    },
    emptyField: "Not specified",
    form: {
      email: "Email",
      password: "Password",
      newPassword: "New password",
      oldPassword: "Your current password",
      passwordConfirm: "Confirm password",
      passwordConfirmFailed: "Not the same passwords.",
      passwordRequirements: "Should contain at least :",
      passwordRequirementsList: {
        number: "10 characters.",
        letter: "1 letter.",
        majLetter: "1 Uppercase letter.",
        didgit: "1 didgit.",
        special: "1 special character."
      },
      newPerson: "Create profil",
      cancel: "Cancel",
      select: {
        title: "Select :",
        optionsSelectedLabel: 'selected',
        noResultsText: 'No results',
        searchPlaceholder: "Search...",
        selectAllLabel: "Select all"
      },
      submitButton: "Submit",
      validate: "Validate",
      confirm: 'Confirm',
      clear: 'Clear',
      clearAll: 'Clear all',
      modify: "Modify",
      datePicker: {
        displayedFormat: "DD/MM/YYYY", // date format usually used
        dateFormat: "DD/MM/YYYY", // In english: date format usually used
        okBtnLabel: 'Confirm selection',
        clearBtnLabel: 'Clear selection',
        cancelBtnLabel: 'Cancel selection',
        nextMonthLabel: 'Next month',
        prevMonthLabel: 'Previous month',
        nextYearLabel: 'Next year',
        prevYearLabel: 'Previous year',
        nextMultiYearLabel: 'Next 24 years',
        prevMultiYearLabel: 'Previous 24 years',
        switchToMultiYearViewLabel: 'Choose year and month',
        switchToDayViewLabel: 'Choose date',
        monthsFull: "January, February, March, April, May, June, July, August, September, October, November, December",
        monthsShort: "Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec",
        weekdaysFull: "Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday",
        weekdaysShort: "Sun, Mon, Tue, Wed, Thu, Fri, Sat",
        weekdaysNarrow: "S, M, T, W, T, F, S",
      },
      timePicker: {
        hoursFormat: "12", // 12 or 24
        hoursPlaceholder: "HH:MM AM/PM", // Omit AM/PM in 24 hours format
        amLabel: "AM",
        pmLabel: "PM",
        invalidLabel: "Invalid date format",
      },
    }
  },
  displayError: {
    clear: "Clear",
    default: "An error occurred.",
    auth_login_401: "Login failed, either email or password is wrong.",
    500: "Server error or network issue, please try later.",
  },
  unknow: "Unknow",
  license: {
    validUntil: (date) => { return `Valid until ${date}` },
    noDate: "No expiry date",
    subLicense: "Sub-license"
  },
  link: {
    goTo: "Go to",
    see: "See",
  },
  router: {
    Accueil: "Logout",
    Home: "Home",
    Accommodation: "Accommodation",
    AccommodationPost: "New accommodation",
    AccommodationUpdate: "Update accommodation",
    AccommodationsList: "Accommodations list",
    Checking: "Checking",
    CheckingPost: "New checking",
    CheckingUpdate: "Update checking",
    CheckingsList: "Checkings list",
    License: "License",
    LicensesList: "Licenses list",
    Profil: "Your profil",
    SignIn: "Sign in",
    ProfilUpdate: "Update profil",
    ChangePassword: "Change password",
    ForgotPassword: "Reset password",
  },
  specific: {
    floor: ({ named }) => {
      let number = named("number");
      if (number >= 0) {
        let ordinal = messages.specific.ordinal(+number + 1);
        return ordinal += " floor"
      }
      else {
        let ordinal = messages.specific.ordinal(Math.abs(number));
        return ordinal += " basement"
      }
    },
    ordinal: (n) => {
      n = Math.abs(n);
      let u = n % 10, d = n % 100;
      if (u == 1 && d != 11) {
        return n + "<sup>st</sup>";
      }
      if (u == 2 && d != 12) {
        return n + "<sup>nd</sup>";
      }
      if (u == 3 && d != 13) {
        return n + "<sup>rd</sup>";
      }
      return n + "<sup>th</sup>";
    },
  },
  // Views/Components
  AccommodationForm: {
    accommodation: {
      access: "Comments",
      buildingYear: "Building year",
      doorNumber: "Door number",
      floor: "Floor",
      garden: "Garden / Outer part",
      isFurnished: "Furnished",
      reference: "Reference",
      surface: "Surface",
      type: "Room"
    },
    title: {
      access: "Access",
      accommodationInfos: "Accommodation",
      new: "New accommodation",
      update: "Update accommodation"
    }
  },
  Accueil: {
    welcome: {
      welcome: "Welcome back !",
      text: "Sign in to use the app."
    },
    subscribe: {
      text: "Don't have an account ?",
      link: {
        text: "Sign in !"
      }
    },
    knowUs: {
      text: "You don't know eecheck ?",
      link: {
        text: "About us",
        desc: "Our showcase website"
      }
    },
    forgotPassword: "Forgot password ?",
    submit: "Sign In",
  },
  Brand: {
    desc: "eccheck brand"
  },
  CheckingView: {
    appointment: "Appointment",
    changeLicenseButton: {
      toPro: "Upgrade to Expert",
      change: "Change license"
    },
    modifyButton: "Modify the checking",
    roomsCountTitle: "Rooms to check"
  },
  CheckingForm: {
    annexe: {
      explanations: "(You can specify the number)"
    },
    appointment: {
      title: "Appointement",
      date: "Select a date",
      time: "Select an hour",
    },
    checking: {
      count: "Count",
      explanations: "Total count of separate rooms to check (including entrance, corridor, WC...).",
      exteriors: "This also includes the private exteriors (garden, terrace...).",
    },
    license: "Select a license :",
    newAddress: "New accommodation",
    selectAccommodation: "Select an accommodation :",
    orCreate: "Or :",
    select: {
      accommodation: "Accommodation",
      occupant: "Occupant",
      owner: "Owner",
      expert: "Expert",
      representative: "Representative"
    },
    title: {
      addAnnexes: "Add annexes",
      checkingInfos: "Infos",
      new: "New checking",
      roomsCount: "Rooms to check",
      update: "Update checking",
      occupants: "Occupants",
      owners: "Owners",
      experts: "Experts",
      representatives: "Representatives"
    }
  },
  ForgotPassword: {
    title: "Password forgot",
  },
  ForgotPasswordComplete: {
    checkmMail: "Please check your mail.",
    confirmMail: "You will receive a confirmation email in a few minutes to reset your password."
  },
  GradientContainer: {
    seeLess: "See less",
    seeMore: "See more",
  },
  LangSwitcher: {
    button: {
      text: "Language",
      desc: "Change language"
    },
    dropdown: {
      "en-EN": "English",
      "fr-FR": "French",
      "ja-JA": "Japanese"
    }
  },
  LicensesList: {
    title: "Your licenses",
    personal: {
      badge: "(personal)",
    },
    group: {
      subs: "Don't have sub-license | 1 sub-license | {n} sub-licenses",
      grantSub: "Give a sub-license",
    },
    owner: "In the name of :",
    credits: "You don't have any expert report | 1 expert report available | {n} expert reports availables",
    addCredits: "Purchase credits",
    upgrade: "Subscribe",
  },
  LicenseSwitcher: {
    button: {
      text: "License",
      desc: "Change license"
    },
  },
  ListAppearanceSwitcher: {
    card: "Display as cards",
    list: "Display as a list",
  },
  Paiement: {
    addCredits: "Purchase more expert reports :",
    title: "Your command",
    total: "Total :",
    One: {
      purchasesOptions: "( 7€/report, 30€/5 reports )"
    }
  },
  Profil: {
    title: "Your profile",
    name: "Name",
    firstName: "First name",
    mailAddress: "E-mail",
    phone: "Phone",
    cellPhoneNumber: "Cellphone",
    phoneNumber: "Phone",
    faxNumber: "Fax",
    address: "Address",
    zipCode: "ZIP code",
    city: "City",
    state: "State",
    country: "Country",
    codes: "Access codes",
    licenses: "1 license | {n} licenses availables",
    licensesButton: "See",
    personNatural: "Natural",
    personLegal: "Legal",
    identificationNumber: "Number",
    identificationNumberType: "Identification",
    img: {
      desc: "Your profile picture"
    }
  },
  ProfilDropdown: {
    toogleTitle: "See user menu"
  },
  ProfilInfos: {
    alt: "Profil picture"
  },
  ProfilUpdate: {
    civility: "Civility :",
    civilityLabel: {
      M: "M.",
      Mme: "Mme"
    },
    title: {
      user: "User",
      phones: "Phones",
      address: "Address",
      image: "Profil picture",
      personType: "Person"
    }
  },
  Register: {
    choosePassword: "Choose password",
    goSignIn: "Please sign in again",
    invalidToken: "Sorry, your validation link has expired.",
    submit: "Confirm registration",
    title: "Registration confirmation"
  },
  RegisterComplete: {
    thankYou: "Thank you for your trust !",
    checkMail: "Please check your mail.",
    confirmMail: "You will receive a confirmation email in a few minutes to finalize your registqration."
  },
  ResetPassword: {
    invalidToken: "Sorry, this link has expired.",
    title: "Reset password",
  },
  Search: {
    clear: "Clear filter",
    find: "Find",
    placeholder: "Search",
  },
  SideNavigation: {
    hide: "Hide",
    show: "Show"
  },
  SignIn: {
    eamil: "Your mail",
    language: "Language",
    submit: "Confirm",
    theme: "Site appearance",
    title: "Registration"
  },
  ThemeSwitcher: {
    button: {
      text: "Appearance",
      desc: "Dark/Light mode"
    },
    dropdown: {
      dark: "Dark",
      system: "Default",
      light: "Light"
    }
  },
}