import 'mdb-vue-ui-kit/css/mdb.min.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from '@/plugins/i18n'
import { Trans } from './plugins/translation'
import helpers from '@/helpers'

const app = createApp(App)

app.use(router).use(store).use(i18n)

// app.config.errorHandler = function(err, vm, info) {
//   console.log(`Error: ${err.toString()}\nInfo: ${info}`);
// }
app.config.warnHandler = function() {
  // console.log(`Warn: ${msg}\nTrace: ${trace}`);
  return
}

app.config.globalProperties.$i18nRoute = Trans.i18nRoute.bind(Trans)
app.config.globalProperties.$helpers = { ...helpers }

app.config.globalProperties.$tcust = function () {
  // arguments[0, 1] = message, params (i18n)
  // arguments[2] = contraction (Boolean)
  // arguments[3] = modifier (String)
  let message;
  try {
    message = i18n.global.t.apply(this, arguments);
    if (arguments[2]) message = i18n.global.t('functions.contraction', { message });
    if (arguments[3]) message = i18n.global.t('functions.modifier', { message, modifier: arguments[3] });
  } catch (error) {
    try {
      message = i18n.global.t('displayError.default');
      if (message === 'displayError.default') throw error
    } catch (error) {
      message = "Error";
    }
  }
  return message
}

const requireComponent = require.context(
  './components/global',
  true,
  /[A-Z]\w+\.(vue)$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  const componentName = fileName.split('/').pop().replace(/\.\w+$/, '');

  app.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

app.mount('#app')
