<template>
  <div class="custom-size d-flex flex-row w-fit">
    <CardContainer
      class="h-100 w-100"
      :class="$store.state.licenseChosenType === 0 ? 'gradient' : $store.state.licenseChosenType === 1 ? 'gradient-group' : 'bg-secondary'"
    >
      <div class="bg-box w-100 px-3 d-flex flex-row align-items-center justify-content-start position-relative">
        <span
          v-if="$store.state.licenseChosenType === 'Sous-license'"
          class="text-nowrap fst-italic text-muted"
        >
          {{ $tcust("license.subLicense") + "&nbsp; - &nbsp;" }}
        </span>
        <span
          v-if="$store.state.licenseChosenType === 1"
          class="text-nowrap fst-italic text-muted"
        >
          {{ "Group&nbsp; - &nbsp;" }}
        </span>
        <span>
          {{ $store.state.licenseChosenType === 0 ? $store.state.user.Person.FullName : $store.state.licenseChosen.FullName || $store.state.licenseChosen.License.OwnerName }}
        </span>
      </div>
    </CardContainer>
    <LicenseSwitcher
      id="customLicenseSwitcher"
      class="ps-0 h-100 ms-n2"
    />
  </div>
</template>

<script setup>
import LicenseSwitcher from "./LicenseSwitcher";

import { onMounted } from "vue";
onMounted(() => {
  document.getElementById('customLicenseSwitcher').style.cssText = "height: 35px !important";
})
</script>

<style lang="scss" scoped>
.custom-size {
  min-width: 230px;
  max-width: 300px;
  & *:not(span) {
    min-height: 35px !important;
    max-height: 35px;
  }
}
.gradient {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
