<template>
  <div class="h-100">
    <Modal />
    <router-view class="h-100" />
  </div>
</template>

<script setup>
import Modal from "@/components/layout/Modal.vue";

import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { mutateApp } from "@/helpers";
import { i18n } from '@/plugins/i18n';

const route = useRoute();
const router = useRouter();
const store = useStore();

const setWindowSize = () => {
  let size = window.innerWidth;
  if (size < 576) size = "xs";
  else if (size < 768) size = "sm";
  else if (size < 992) size = "md";
  else if (size < 1200) size = "lg";
  else if (size < 1400) size = "xl";
  else size = "xxl";
  mutateApp("windowSize", size);
};

onMounted(() => {
  setWindowSize();
  window.addEventListener("resize", function() {
    setWindowSize();
  });

  window.addEventListener('load', function (event) {
    let bug = window.location.href.indexOf('?');
    if (bug > 0) {
      event.preventDefault();
      window.location.replace(window.location.href.slice(0, bug));
    }
  });

  document.addEventListener("keypress", (event) => {
      if (event.key === 'Enter' && document.querySelector('form') && (!event.path[0].attributes || !event.path[0].attributes.type || !event.path[0].attributes.type.value === 'submit')) {
        event.preventDefault();
      }
    }
  );

  document.addEventListener("mousedown", (event) => {
      mutateApp("clickPath", event.composedPath());
      shrinkTexts();
    },
    { capture: true }
  );
});

const appWindowSize = computed(() => {
  return store.state.app.windowSize
});
const listAppearanceChosen = computed(() => {
  return store.state.app.listAppearanceChosen
});
const listAppearanceImposed = computed(() => {
  return store.state.app.listAppearanceImposed
});
const error = computed(() => {
  return store.state.app.error
});

const listAppearance = ref('Card');

const setListAppearance = () => {
  if (appWindowSize.value === "xs") {
    listAppearance.value = "Card";
    mutateApp('listAppearanceImposed', true);
  }
  else if (appWindowSize.value === "sm") {
    listAppearance.value = "List";
    mutateApp('listAppearanceImposed', true);
  }
  else {
    listAppearance.value = listAppearanceImposed.value || listAppearanceChosen.value;
    mutateApp('listAppearanceImposed', false);
  }
  mutateApp('listAppearance', listAppearance.value)
};

const shrinkTexts = (cont = false) => {
  setTimeout(() => {
    let spans = document.querySelectorAll('span');
    for (let span of spans) {
      let parent = span.parentNode;
      span.style.fontSize = '';
      if (span.offsetWidth > parent.offsetWidth) {
        if (!cont) {
          span.textContent = i18n.global.t('functions.contraction', { message: span.textContent });
          shrinkTexts(cont = true);
        }
        let fontSize = window.getComputedStyle(span).fontSize.slice(0,-2);
        while (span.offsetWidth > parent.offsetWidth) {
          fontSize--;
          span.style.cssText = span.style.cssText.replace(/font-size: \d+px !important;/g, '');
          span.style.cssText = span.style.cssText + ` font-size: ${fontSize}px !important;`;
          if (fontSize === 14) break
        }
      }
    }
  }, 500);
};

onMounted(() => {
  setListAppearance();
  shrinkTexts();
});

watch([appWindowSize, listAppearanceChosen, listAppearanceImposed], () => {
  setListAppearance();
  shrinkTexts();
});
watch(route, () => {
  if (route.name === 'Accueil' && store.state.user) router.go(); // Reset store
});
watch(error, () => {
  if (error.value.status === 401 && route.meta.requireAuth) router.go(); // Session expired
});
</script>

<style lang="scss">
@import "mdb-vue-ui-kit/src/scss/index.pro.scss";
@import "styles/var.scss";

$light-theme: mdb-light-theme($light-theme-primary, $light-theme-secondary);
$dark-theme: mdb-dark-theme($dark-theme-primary, $dark-theme-secondary);

#app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;

  font-family: Roboto, Helvetica, Arial, sans-serif;

  * {
    transition: width height transform 0.5s;
  }

  &.light-theme {
    @include mdb-theme($light-theme);
    color: $gray-900 !important;
    background-color: $gray-100 !important;
  }
  &.dark-theme {
    @include mdb-theme($dark-theme);
  }

  *:not(.fw-bold) {
    text-decoration-thickness: 1px !important;
  }
  *:focus span:not(.fas) {
    text-decoration: underline;
  }

  i.fas, i.far {
    text-decoration: none !important;
  }

  input {
    color: currentColor !important;
  }

  // --- MDB ---
  &.light-theme {
    nav, [class*="btn-outline-"], .modal-content {
      background-color: $gray-200 !important;
    }
    .btn {
      font-weight: bold !important;
      &.btn-outline-primary{
        color: $dark !important;
      }
      &[class*="btn-outline-"]:hover, &[class*="btn-outline-"]:focus {
        color: $light-theme-primary !important;
      }
    }
    .btn.btn-white {
      background-color: $gray-100 !important;
    }
    .btn.btn-transparent.btn-icon {
      &:hover, &:focus {
        color: $dark !important;
      }
    }
    .card-header {
      background-color: transparent !important;
    }
    .datepicker, .timepicker {
      button:hover, button:focus {
        i {
          color: $light-theme-primary !important;
        }
      }
    }
    .opacity-85 {
      opacity: 85%;
    }
    .text-muted {
      color: $gray-800 !important;
    }
  }

  &.dark-theme {
    // .btn:hover, .btn:focus {
      // background-color: $white !important;
      // color: $dark !important;
    // }
    .btn.btn-transparent.btn-icon {
      &:hover, &:focus {
        background-color: transparent !important;
        color: $white !important;
      }
    }
    .btn.btn-outline-primary:hover, .btn.btn-outline-primary:focus {
      color: $white !important;
    }
    .btn-outline-secondary {
      color: $white !important;
    }
    .card-header {
      background-color: transparent !important;
    }
    .text-muted {
      color: $gray-300 !important;
    }
  }

  .badge {
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
  }
  .btn-secondary {
    color: $white !important;
  }
  .dropdown-menu {
    z-index: 1500;
  }
  i, i::before {
    text-decoration: none !important;
  }
  .form-check {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-check-input:not([type=checkbox]) {
    height: 1rem;
    width: 1rem;
    margin-top: -2px;
    &::after {
      height: 0.6rem;
      width: 0.6rem;
    }
  }
  .form-check-label {
    width: calc(100% - 1.1rem);
  }
  main *:not(.custom-textarea, .select-wrapper) > .form-outline {
    max-width: 250px;
    margin: auto;
  }
  .form-outline .form-control.tiny {
    padding-right: 5px;
    height: 1.8rem;
    & + label {
      padding-top: 0px !important;
    }
  }
  .select-arrow {
    border-left: solid 1px;
    padding-left: 0.25rem;
  }
  .select-clear-btn {
    padding-right: 0.25rem;
    margin-top: -1px;
  }

  // --- CUSTOM ---
  a:hover, a:focus, a:hover span, a:focus span {
    text-decoration: underline;
  }

  input, textarea {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.07), 0 2px 4px -1px rgba(0, 0, 0, 0.05) !important;
  }
  .limit *:not(.off-limit) {
    max-width: 95vw !important;

  }

  .aria-only {
    width: 0;
    height: 0;
  }
  .above {
    z-index: 1250;
  }
  .bg-box {
    height: 100%;
    width: 100%;
    flex-grow: 1;
  }
  &.light-theme, &.dark-theme {
    .border-current {
      border-color: rgba(gray, 0.7) !important;
    }
  }
  .btn:hover, .btn:focus {
    & span {
      text-decoration: underline;
    }
    &.chart-bg-group {
      background-color: darken($group-chart, 5%) !important;
    }
    &.chart-bg-sub {
      background-color: darken($light-theme-secondary, 5%) !important;
    }
  }
  .bg-primary {
    color: $dark !important;
  }
  .chart-bg-group, .chart-bg-sub {
    color: $white !important;
  }
  .color-current {
    color: currentColor !important;
  }
  .custom-card {
    width: 300px;
  }
  .cust-date {
    font-weight: bold;
  }
  .cust-time {
    font-weight: bold;
  }
  .main-view {
    overflow-y: scroll;
  }
  .h-fit {
    height: fit-content;
    min-height: fit-content !important;
  }
  .text-normal {
    text-transform: none !important;
  }
  .text-shrink {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: block;
    max-width: 100%;
    > span {
      width: fit-content;
    }
  }
  .w-fit {
    width: fit-content;
  }

  // @media (max-width: 359.98px) { // fold...
  //   :not(.gradient), :not(.gradient-group) {
  //     .p-3, .p-4 {
  //       padding-left: 0.25rem !important;
  //       padding-right: 0.25rem !important;
  //       & > * {
  //         margin: auto;
  //       }
  //     }
  //   }
  // }

  &.light-theme {
    .gradient {
      background: linear-gradient(
        215deg,
        $light-theme-primary 10%,
        $light-theme-secondary 90%
      );
    }
    .gradient-group {
      background: linear-gradient(
        215deg,
        $group-chart 10%,
        $light-theme-secondary 90%
      );
    }
    .bg-box {
      background-color: rgba($white, 0.85) !important;
      // *:not([class*="bg-"]) {
      //   color: $dark;
      // }
    }

    .bg-secondary > *, .btn-secondary > * {
      color: $white !important;
    }

    a:not(:hover):not(:focus):not(.sidenav-link) {
      // color: #CA3B39 !important;
      color: darken($light-theme-primary, 15%) !important;
      i {
        color: $dark;
      }
    }
    a:hover, a:focus, a:hover span, a:focus span {
      color: $dark !important;
    }

    header {
      background-color: rgba($white, 0.3) !important;
    }

    input:not(:disabled), textarea:not(:disabled), .bg-input {
      background-color: $gray-100 !important;
    }

    .form-outline .form-control:disabled {
      background-color: $gray-400;
    }

    .icon-btn-primary {
      color: $light-theme-primary;
      &:hover, &:focus {
        color: $gray-400 !important;
      }
    }

    .chart-color {
      &-one {
        color: $light-theme-primary;
      }
      &-group {
        color: $group-chart;
      }
      &-sub {
        color: $light-theme-secondary;
      }
    }
    .chart-bg {
      &-one {
        background-color: $light-theme-primary;
      }
      &-group {
        background-color: $group-chart;
      }
      &-sub {
        background-color: $light-theme-secondary;
      }
    }
  }

  &.dark-theme {
    .gradient {
      background: linear-gradient(
        215deg,
        $dark-theme-primary 10%,
        $dark-theme-secondary 90%
      );
    }
    .gradient-group {
      background: linear-gradient(
        215deg,
        $group-chart 10%,
        $dark-theme-secondary 90%
      );
    }
    .bg-box {
      background-color: rgba($dark, 0.75) !important;
      color: $white !important;
    }

    header,
    .sidenav {
      background-color: $dark !important;
    }

    input:not(:disabled), textarea:not(:disabled), .bg-input {
      background-color: rgba($gray-900, 0.3) !important;
    }

    .icon-btn-primary {
      color: $dark-theme-primary;
      &:hover, &:focus {
        color: $white !important;
      }
    }

    .chart-color {
      &-one {
        color: $dark-theme-primary;
      }
      &-group {
        color: $group-chart;
      }
      &-sub {
        color: $dark-theme-secondary;
      }
    }
    .chart-bg {
      &-one {
        background-color: $dark-theme-primary;
      }
      &-group {
        background-color: $group-chart;
      }
      &-sub {
        background-color: $dark-theme-secondary;
      }
    }
  }
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

::-webkit-scrollbar {
  background-color: transparent;
  overflow-y: overlay;
  border: none;
  border-radius: 5px;
  width: 0;
  height: 0;
}
// ::-webkit-scrollbar-track {
  // background: red;
  // border-left: solid 1px red;
// }
::-webkit-scrollbar-thumb {
  // background: red;
  border-radius: 5px;
}
</style>
