import store from '@/store'

export function mutateStore(fct, value) {
  store.dispatch("mutateStore", {
    fct,
    value,
  });
}

export function mutateKey(sKey, body) {
  if (typeof body === "object" && !Array.isArray(body) && body !== null) {
    let sObj = { ...store.state[sKey] };
    body = Object.assign(sObj, body);
  }
  mutateStore("mutateKey", { sKey, body });
}

export function mutateApp(appKey, value) {
  mutateStore("mutateApp", { aKey: appKey, body: value });
}

export default {
  mutateStore,
  mutateKey,
  mutateApp
}