import { createStore } from "vuex";
const axios = require('axios');
const eecheckApp = axios.create({
  baseURL: 'https://api.eecheck.app/',
});

export default createStore({
  state: {
    // app
    app: {
      clickPath: null,
      theme: null,
      themeChosen: 'system',
      listAppearance: null,
      listAppearanceChosen: 'Card',
      listAppearanceImposed: false,
      windowSize: null,
      search: null,
      modal: null,
      error: null,
    },

    // accommodations
    accommodationChosen: null,
    accommodationsList: [],

    // checkings
    checkingChosen: null,
    checkingsList: [],

    // licenses
    licenseChosen: null,
    licenseChosenKey: null,
    licenseChosenType: 0,

    // modal stash
    modalStash: null,

    //person
    person: null,
    personsList: [],

    //photos
    photos: [],

    //user
    user: null,

    // others
    companyIdentificationNumberTypeValues: null,
  },
  mutations: {
    mutateKey(state, payload) {
      let mutate = payload.sKey;
      delete payload.sKey;
      if (Array.isArray(state[mutate]) && !Array.isArray(payload.body)) {
        if (state[mutate].length > 0) {
          for (let elem of state[mutate]) {
            if (JSON.stringify(elem) == JSON.stringify(payload.body)) return;
          }
        }
        state[mutate].push(payload.body);
      } else {
        state[mutate] = payload.body;
      }
    },
    mutateApp(state, payload) {
      let app = { ...state.app };
      app[payload.aKey] = payload.body;
      state.app = app;
    },
  },
  actions: {
    mutateStore(context, payload) {
      context.commit(payload.fct, payload.value || null);
    },
    async login(context, payload) {
      return context.dispatch("request", {
        method: "post",
        url: "auth/login",
        data: payload, // pas de token, options = data
      })
      .then((res) => {
        return context.dispatch("mutateStore", {
          fct: "mutateKey",
          value: { sKey: "user", body: res },
        })
      })
    },
    async changepassword(context, payload) {
      return context.dispatch("request", {
        method: "post",
        url: "auth/changepassword",
        data: { ...payload }
      })
    },
    async accomodationAddOrUpdate(context, payload) {
      return context.dispatch("request", {
        method: "post",
        url: "accomodation/addorupdate",
        data: { data: { ...payload } }
      })
      .then((res) => {
        if (context.state.app.modal) {
          return context.dispatch('getAllAccommodations')
          .then(() => {
            return context.dispatch("mutateStore", {
              fct: "mutateKey",
              value: { sKey: "accommodationChosen", body: res },
            })
          })
        }
      })
    },
    async checkingAddOrUpdate(context, payload) {
      return context.dispatch("request", {
        method: "post",
        url: "checking/addorupdate",
        data: { ...payload }
      })
    },
    async personAddOrUpdate(context, payload) {
      if (context.state.app.modal) {
        context.dispatch("mutateStore", {
          fct: "mutateKey",
          value: { sKey: "modalStash", body: payload },
        })
        let app = { ...context.state.app };
        app.modal = false;
        context.dispatch("mutateStore", {
          fct: "mutateKey",
          value: { sKey: "app", body: app },
        })
        return
      }
      else return context.dispatch("request", {
        method: "post",
        url: "person/addorupdate",
        data: { ...payload }
      })
    },
    async getAllCheckings(context) {
      return context.dispatch("request", {
        method: "get",
        url: "Checking/GetAll",
      })
      .then((res) => {
        return context.dispatch("mutateStore", {
          fct: "mutateKey",
          value: { sKey: "checkingsList", body: res },
        })
      })
    },
    async getAllWithFilters(context, payload) {
      // { "LicenseKey": String, "AccomodationId": Number } null = non pris en charge dans la req
      let data = {
        LicenseKey: payload.LicenseKey || null,
        AccomodationId: payload.AccomodationId || null
      };
      return context.dispatch("request", {
        method: "post",
        url: "Checking/GetAllWithFilters",
        data,
      })
      .then((res) => {
        return res
      })
    },
    async getAllAccommodations(context) {
      return context.dispatch("request", {
        method: "get",
        url: "Accomodation/GetAll",
      })
      .then((res) => {
        return context.dispatch("mutateStore", {
          fct: "mutateKey",
          value: { sKey: "accommodationsList", body: res },
        })
      })
    },
    async getAllPersons(context) {
      return context.dispatch("request", {
        method: "get",
        url: "Person/GetAll",
      })
      .then((res) => {
        return context.dispatch("mutateStore", {
          fct: "mutateKey",
          value: { sKey: "personsList", body: res },
        })
      })
    },
    async getCompanyIdentificationNumberTypeValues(context) {
      return context.dispatch("request", {
        method: "get",
        url: "enums/GetCompanyIdentificationNumberTypeValues",
      })
      .then((res) => {
        return context.dispatch("mutateStore", {
          fct: "mutateKey",
          value: { sKey: "companyIdentificationNumberTypeValues", body: res },
        })
      })
    },
    async getPhoto(context, Id) {
      return await new Promise((resolve) => {
        for (let photo of context.state.photos) {
          if (photo.Id === Id) return resolve(photo)
        }
        resolve(context.dispatch("request", {
          method: "get",
          url: "Photo/Get",
          data: { Id }
        }))
      })
      .then((res) => {
        if (res) {
          context.dispatch("mutateStore", {
            fct: "mutateKey",
            value: { sKey: "photos", body: res },
          })
        }
        return res
      })
    },
    async getChecking(context, Id) {
      return context.dispatch("request", {
        method: "get",
        url: "Checking/Get",
        data: { Id }
      })
      .then((res) => {
        return res
      })
    },
    async getUser(context) {
      return context.dispatch("request", {
        method: "get",
        url: "User/Get",
        data: { userName: this.state.user.UserName }
      })
      .then((res) => {
        return context.dispatch("mutateStore", {
          fct: "mutateKey",
          value: { sKey: "user", body: { ...res, Token: this.state.user.Token } },
        })
      })
    },
    async getUserPhoto(context, UserName) {
      return context.dispatch("request", {
        method: "get",
        url: "Photo/GetUser",
        data: { UserName }
      })
      .then((res) => {
        return res
      })
    },
    async register(context, payload) {
      return context.dispatch("request", {
        method: "post",
        url: "auth/register",
        data: { ...payload }
      })
    },
    async registerConfirm(context, payload) {
      return context.dispatch("request", {
        method: "post",
        url: "auth/registerconfirm",
        data: { ...payload }
      })
    },
    async resetPassword(context, payload) {
      return context.dispatch("request", {
        method: "post",
        url: "auth/resetpassword",
        data: { ...payload }
      })
    },
    async resetPasswordConfirm(context, payload) {
      return context.dispatch("request", {
        method: "post",
        url: "auth/resetpasswordconfirm",
        data: { ...payload }
      })
    },
    async validateToken(context, Token) {
      return context.dispatch("request", {
        method: "get",
        url: `auth/ValidateToken?token=${Token}`,
      })
    },
    async request(context, payload) {
      let options = payload.options || context.getters.axiosOptions;
      delete payload.options;
      if (payload.data) {
        if (payload.method === 'get') {
          payload.url += '?';
          for (let entries of Object.entries(payload.data)) {
            payload.url += `${entries[0]}=${entries[1]}&`
          }
          payload.url = payload.url.slice(0, -1);
          delete payload.data;
        }
      }
      payload = { ...payload, ...options };
      return eecheckApp(payload)
      .catch((error) => {
        error = error.response;
        if (!error) error = {
          "config": {
            "url": payload.url
          },
          "status": 500,
        };
        if (error.status === 429) { // Common error 'Too many request', just wait and retry
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(eecheckApp(payload))
            }, 1100);
          })
        }
        context.dispatch("mutateStore", {
          fct: "mutateApp",
          value: { aKey: "error", body: error },
        })
        throw error
      })
      .then((res) => {
        if (context.state.app.error) context.dispatch("mutateStore", {
          fct: "mutateApp",
          value: { aKey: "error", body: false },
        })
        if (res.data.data) return res.data.data
        else return res.data
      })
      .then((res) => {
        if (context.state.app.modal) {
          context.dispatch("mutateStore", {
            fct: "mutateKey",
            value: { sKey: "modalStash", body: res },
          })
          context.dispatch("mutateStore", {
            fct: "mutateApp",
            value: { aKey: "modal", body: false },
          })
        }
        return res
      })
    }
  },
  getters: {
    axiosOptions: (state) => {
      if (state.user && state.user.Token) return {
        headers: {
          Authorization: `Bearer ${state.user.Token}`
        }
      }
      else return {}
    },
    nextCheckingsList: (state) => {
      let today = new Date().setHours(0, 0, 0, 0);
      return state.checkingsList.length > 0 ? state.checkingsList.filter(checking => new Date(checking.AppointmentDateTime) > today) : []
    }
  },
  modules: {},
});
