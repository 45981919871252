import { computed } from "vue";
import { useStore } from "vuex";
import { mutateApp } from '@/helpers'

export default function Setup() {
  const store = useStore();

  const search = computed({
    get: () => {
      return store.state.app.search
    },
    set: (value) => {
      return mutateApp('search', value)
    }
  });

  return {
    search
  };
}
